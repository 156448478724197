<template>
    <GlobalBannerEffect />
    <section class="my-24 relative">
        <SectionCollections :collections="dataRender?.collections" />
        <GlobalPagination
            v-if="dataRender?.total_page > 1"
            :pages="dataRender?.total_page"
            :currentPage="+currentPage"
            @page-changed="getCurrentPage"
            class="container"
        />
        <GlobalLoading v-if="loadingFilter" />
    </section>
</template>

<script setup lang="ts">
import SectionCollections from './SectionCollections'
const globalSettings = inject('globalSettings')
const { getProductCollections } = useProducts(globalSettings)
const loadingFilter = ref(false)
const currentPage = ref(useRoute().query.page ?? 1)
const dataRender = ref()
const LIMIT = 8

const fetchCollects = async (limit: number, currentPage: number) => {
    dataRender.value = await getProductCollections({ query: { limit: limit, page: currentPage } })
    loadingFilter.value = false
}
const getCurrentPage = (page: number) => {
    currentPage.value = page
    fetchCollects(LIMIT, currentPage.value)
}
onMounted(() => {
    fetchCollects(LIMIT, currentPage.value)
})

useDirectusCollectionSeo('collection_index', {})
</script>

<style></style>
